<template>
  <b-container class="not-found-page">
    <div class="centered ">
      <h1><i class="not-found-icon nc-icon nc-puzzle-10"></i>404</h1>
      <h2>¡Oh no! Parece que estas perdido</h2>
      <p>Recuerda que en la aplicacion de autoregistro el unico</p>
      <p> url valido es <code>https://ar.eclock.com.mx/#/r/linkID</code></p>
      <p>Donde linkID es un id unico, por favor ingresa al url que se te proporciono</p>
      <p>En caso de problemas contacta a quien te lo proporciono</p>
    </div>
  </b-container>
</template>

<script>
import AuthLayout from 'src/Vistas/LayOuts/AuthLayout.vue';

export default {
  components: {
    AuthLayout
  },
  mounted() {


  }
};
</script>
<style lang="scss">

.not-found-page {

  .full-page>.content,
  .centered {
    min-height: calc(100vh - 160px);
  }

  .not-found-icon {
    margin-right: 10px;
  }

  .centered {

    h1,
    i {
      font-size: 50px;
    }

    p {
      font-size: 20px;
    }

    display: flex;
    padding-bottom: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
</style>
