import HomeLayout from "src/Vistas/LayOuts/HomeLayout.vue";
import VistasAuthLayout from "src/Vistas/LayOuts/AuthLayout.vue";
// GeneralViews
import PaginaNoEncontrada from "src/Vistas/Main/PaginaNoEncontrada.vue";

let authVistas = {
  path: "/",
  component: VistasAuthLayout,
  name: "Authentication",
  children: [

    {
      path: "/login",
      name: "Inicio de sesión",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Main/InicioSesion.vue"
        ),
    },
  ]
}
// GeneralViews
const routes = [
  {
    path: "/home",
    name: "Home",
    component: PaginaNoEncontrada,
  },
  {
    path: "/",
    name: "Default",
    component: () =>
      import(/* webpackChunkName: "default" */ "src/Vistas/Main/Default.vue"),
  },

  {
    path:"/r/:LinkID",
    name:"AutoRegistro",
    component: () => import(/* webpackChunkName: "default" */ "src/Vistas/Formulario/_LinkID"),
  },
  {
    path:"/mp/:UniqueID",
    name:"AutoRegistro",
    component: () => import(/* webpackChunkName: "default" */ "src/Vistas/Formulario/_UniqueID"),
  },
  {
    path:"/mp/:UniqueID",
    name:"AutoRegistro",
    component: () => import(/* webpackChunkName: "default" */ "src/Vistas/Formulario/_UniqueID"),
  },
  {
    path:"/finalizado",
    name:"AutoRegistro",
    component: () => import(/* webpackChunkName: "default" */ "src/Vistas/Formulario/Finalizacion"),
  },
  
  // authVistas,
  // Agregar código para mostrar vacio
  { path: "*", name: "Página no encontrada", component: PaginaNoEncontrada },
];

export default routes;
