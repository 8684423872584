import Axios from "axios";
//const isProd = process.env.NODE_ENV === "production";
/*
import Base from '@/api-services/base'

//const BaseURL = 'http://localhost:50598';
const USUARIOS = `${Base.URL}/usuarios`;
const SESIONES = `${Base.URL}/sesiones`;*/
// const ApiKey = "76a3cd4c-15ed-4227-8fa3-8ad3d01f53ef";

var config = {
  headers: {
    "Content-type": "application/json; charset=utf-8 ; multipart/form-dat",
    "Access-Control-Allow-Headers": "content-type",
    //   'Access-Control-Allow-Origin':'http://localhost:8118',
    //   "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
    //   "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
  },
};

export default {
  header() {
    //console.debug(localStorage.sesion);
    var config = {
      headers: {
        Authorization: localStorage.sesion,
        ApiKey: process.env.VUE_APP_API_KEY,
      },
    };
    return config;
  },
  headerMultipart(onUploadProgress) {
    //console.debug(localStorage.sesion);
    var config = {
      headers: {
        Authorization: localStorage.sesion,
        "Content-Type": "multipart/form-data",
        ApiKey: process.env.VUE_APP_API_KEY,
      },
    };
    if (onUploadProgress != undefined)
      config.onUploadProgress = onUploadProgress;
    return config;
  },
  
  /*  usuario_nuevo(data) {
        return Axios.post(USUARIOS, data);
    },
    sesion_nueva(data) {
        console.debug(SESIONES);
        return Axios.post(SESIONES, data, config);
    },

*/
};
